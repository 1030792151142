<template>
  <div :class="`wrapper wrapper--border wrapper--${bu} m-3 p-2`">
    <div class="_text-center p2">
      <h2>Afgeprijsde voertuigen</h2>
      <span @click="previousWeek"><i class="fas fa-arrow-left" /></span>
      <span class="m2">Week: {{ week_number }}, {{ year_number }}</span>
      <span @click="nextWeek"
        ><i @click="nextWeek" class="fas fa-arrow-right"
      /></span>
    </div>
    <hr />
    <Loading v-if="loading" />
    <div v-else>
      <h3 v-if="discounted_items.length == 0" class="text-center">Geen Data</h3>
      <div v-else>
        <Tabs>
          <Tab
            v-for="(buyer, key) in discounted_items"
            :key="key"
            :title="`${key}`"
          >
            <div class="flexbox spaceevenly">
              <table :class="`table__border--${bu} m-3 p-2`">
                <thead :class="`table__head--${bu}`">
                  <th>item</th>
                  <th>Oude prijs</th>
                  <th>Afgeprijst</th>
                  <th>Afgeprijst op</th>
                </thead>
                <tbody>
                  <tr v-for="(item, key) in buyer" :key="key">
                    <td>
                      <ImageHover :itemnummer="item.Itemnummer" :bu="bu" />
                    </td>
                    <td class="table__data--right">€ {{ item.oud }}</td>
                    <td class="table__data--right">€ {{ item.nieuw }}</td>
                    <td class="table__data--right">{{ item.Datum }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </Tab>
        </Tabs>
      </div>
    </div>
  </div>
</template>

<script>
import request from "@/functions/request.js";
import Loading from "@/components/Loading.vue";
import moment from "moment";
import Tabs from "@/components/Tabs/Tabs.vue";
import Tab from "@/components/Tabs/Tab.vue";
import ImageHover from "@/components/ImageHover.vue";
import {
  subtractTime,
  addTime,
  getWeekNumber,
  getYearNumber,
} from "@/functions/date.js";

export default {
  props: ["bu"],
  components: { Loading, Tab, Tabs, ImageHover },
  data: () => ({
    discounted_items: null,
    full_date: null,
    loading: null,
  }),
  created() {
    this.full_date = moment();
    this.getData(this.bu);
  },
  watch: {
    bu(newbu) {
      this.full_date = moment();
      this.getData(newbu);
    },
  },
  methods: {
    getData(bu) {
      this.loading = true;
      this.week_number = getWeekNumber(this.full_date);
      this.year_number = getYearNumber(this.full_date);
      request(
        `discounted-vehicles/${bu}/${this.year_number}/${this.week_number}`,
        "GET"
      ).then(({ discounted_items }) => {
        this.discounted_items = discounted_items;
        this.loading = false;
      });
    },

    previousWeek() {
      this.full_date = subtractTime(1, this.full_date, "weeks");
      this.getData(this.bu);
    },

    nextWeek() {
      this.full_date = addTime(1, this.full_date, "weeks");
      this.getData(this.bu);
    },
  },
};
</script>
